import React from "react";
import "../../assets/css/featured-product.css";
//traslate
import { useTranslation, Trans } from "react-i18next";

export const FeaturesProduct = () => {
  const shape = require("../../assets/img/epixpro/shape.webp");
  //traslate
  const { t, i18n } = useTranslation("global");

  return (
    <div className="features-product">
      <div className="container">
        <div className="title">
          <h2>{t("features-product.title")}</h2>
          <img src={shape} alt="featured products charlieapps" />
        </div>
        <p>
          {t("features-product.description")}
          <span>{t("features-product.description-span")}</span>
          {t("features-product.description2")}
        </p>
      </div>
    </div>
  );
};
