import React from "react";

import "../../assets/css/epixpro.css";
import { useTranslation, Trans } from "react-i18next";

//images
const logo = require("../../assets/img/epixpro/logo_epixpro.webp");
const img1 = require("../../assets/img/epixpro/img-a.webp");
const download = require("../../assets/img/epixpro/img-c.webp");
//traslate

export const EpixPro = () => {
  const { t, i18n } = useTranslation("global");
  return (
    <div className="epixpro">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 view-app order-lg-1 order-md-1 order-2">
            <img src={img1} alt="epixpro epix pro tools" />
          </div>
          <div className="col-lg-6 col-md-6 col-12 description order-lg-2 order-md-2 order-1">
            <img src={logo} alt="epixpro epix pro tools" className="logo" />
            <p className="title">
              <span>{t("features-product.product-epixpro.title")}</span>
            </p>
            <p>{t("features-product.product-epixpro.description")}</p>
            <button
              className="btn rt-btn"
              onClick={() => (window.location.href = "https://app.epixpro.com")}
            >
              {t("features-product.product-epixpro.button")}
            </button>
            <div className="download-epixpro">
              <img
                src={download}
                alt="download epixpro epix pro tools"
                onClick={() =>
                  (window.location.href =
                    "https://apps.apple.com/us/app/epixpro/id1482789807")
                }
              />
              <p>{t("features-product.product-epixpro.available")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
