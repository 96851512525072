import React from "react";
import "../../assets/css/edmLive.css";
import { useTranslation, Trans } from "react-i18next";

//images
const logo = require("../../assets/img/edmLive/img-e.webp");
const download = require("../../assets/img/edmLive/img-c.webp");
const imageContent = require("../../assets/img/edmLive/img-b.webp");

export const EdmLive = () => {
  const { t, i18n } = useTranslation("global");
  return (
    <div className="edm-live">
      <div className="bg-edm">
        <div className="edm-top-bg"></div>
        <div className="edm-bottom-bg"></div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 description order-lg-1 order-md-1 order-2">
            <p>
              <span>{t("features-product.product-edmlive.title")}</span>
            </p>
            <p className="text-description">
              {t("features-product.product-edmlive.description")}
            </p>
            <button
              className="btn rt-btn"
              onClick={() =>
                (window.location.href = "https://edmlive.charlieapps.com/home")
              }
            >
              <p>
                {t("features-product.product-edmlive.button")}
                <span>{t("features-product.product-edmlive.button-span")}</span>
                {t("features-product.product-edmlive.button2")}
              </p>
            </button>
            <img src={logo} alt="EDM Live" className="logo" />
            <div className="download-edm-live">
              <img
                src={download}
                alt="download EDM Live"
                onClick={() =>
                  (window.location.href =
                    "https://apps.apple.com/us/app/edm-live/id449940080")
                }
              />
              <p>{t("features-product.product-edmlive.available")}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 view-app order-lg-2 order-md-2 order-1">
            <img src={imageContent} alt="EDM Live" />
          </div>
        </div>
      </div>
    </div>
  );
};
